import React, { useRef, useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import FileField from './elements/file'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'

import axios from "axios"
import * as qs from "query-string"

function CareerForms(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const [file, setFile] = useState(""); // storing the uploaded file

  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element


  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "Career",
        error_text: "Highlighted fields are required | invalid",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "career_user",
        email_temp_admin: "career_admin",
        email_server_func: "careers",
        event_tracking: "careers",
        page_url: "/apply-for-job"
      },
      {
        grpmd: "12",
        label: "Your name",
        placeholder: "",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        class: "mb-4"
      },
      {
        grpmd: "12",
        label: "Email",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        class: "mb-4"
      },
      {
        grpmd: "12",
        label: "Telephone Number",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        class: "mb-4"
      },
      {
        grpmd: "12",
        label: "Attach Your CV (doc or PDF only):",
        placeholder: "Click to select a file",
        name: "file",
        type: "file",
        element: "file",
        required: true,
        accept:".pdf,.doc",
        fieldClass: "form-control-browsefile custom custom-file",
        labelClass: "content_b-18"
      },
      {
        type: "hidden",
        element: "input",
        name:"cvname"
      },
      {
        type:"hidden",
        name:"subject",
        element: "input",
        value:"Holroyds - Career"
      },
      {
        name: "Send Your CV",
        type:"submit",
        element: "button",
        value: "submit",
        class: "btn btn-primary"
      },
      {
        text: 'By clicking Send Message, you agree to our <a href="/terms-of-use" className="content-link">Terms & Conditions</a> and <a href="/privacy-policy" className="content-link">Privacy Policy</a>. ',
        element: "html",
        class: "policy"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

    const handleAttachment = event => {

      const file = event.target.files[0]; // accesing file
      console.log(file.name);
      setFile(file); // storing file
      const formData = new FormData();
      formData.append('file', file); // appending file
      const axiosOptions_netlify = {
        url: '/.netlify/functions/server/upload',
        method: "post",
        headers: {"Content-Type": "multipart/form-data"},
        data:formData,
      }

      axios(axiosOptions_netlify)
        .then(response => {
          console.log('file upload', response)
        })
      .catch(err =>
          console.log(err)
      );



  };

  useEffect(() => {
    if (token !== '') {

      // lets send mail
      const axiosOptions_email = {
        url: '/.netlify/functions/server/'+fields[0].email_server_func,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_email)
        .then(response => {
          console.log('mail sent!')
        })
        .catch(err =>
          console.log(err)
        );

      formvalues['g-recaptcha-response'] = token;

      const axiosOptions_netlify = {
        url: fields[0].page_url,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_netlify)
        .then(response => {
          console.log('data stored')
        })
        .catch(err =>
          console.log(err)
        );
      const url = typeof window !== 'undefined' ? window.location.href : ''  
      if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        fields[0].formname = 'Get mortgage help';
      }
      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      if(json['subject']) {
        json['formname']= 'Get mortgage help';
      }
      json['g-recaptcha-response'] = token;
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();
      json['cvname'] = +dd+mm+yyyy+"-"+file.name;
      json['subject'] = 'Holroyds - Career';

      json['file'] = file;
      setFile(file);

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      
    }
  };

  return (
    <div className="form stbform">
    <div ref={myRef} />

    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>}

    {showthankyou && <div className="alert-success">
      <p>{fields[0].success_text}</p>
    </div>}

    
      <Form name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} data-netlify="true" netlify-honeypot="bot-field" data-netlify-recaptcha="true">
        <input type="hidden" name="form-name" value={fields[0].formname} />
        <input type="hidden" name="bot-field" />
        <Form.Row>
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ( "file" === field.element ) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  handlechange={handleAttachment}
                  required={field.required}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
        }
        </Form.Row>
      </Form>
    </div>
  );
}


const CareerFormPage = (props) => (
  <CareerForms subject={props.subject} />
)

export default CareerFormPage