import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * Assets
 */
import '../contact/assets/styles/_index.scss';

/**
 * Components
 */
import CareerForm from '@components/forms/career-form';

const ApplyforJob = () => {
    return (
        <div 
            className="contact"
        >
            <Container>
                <CareerForm />
            </Container>
        </div>
    )
}

export default ApplyforJob;
