import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * Assets
 */
import './assets/styles/_index.scss';

/**
 * Components
 */
import ContactForm from '@components/forms/contact-form';

const Contact = () => {
    return (
        <div 
            className="contact"
        >
            <Container>
                <ContactForm />
            </Container>
        </div>
    )
}

export default Contact;
