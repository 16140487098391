import React from "react";
import { Link } from 'gatsby'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image"


/**
 * Assets
 */

import './assets/styles/_index.scss';
// import Map from '@components/map/map';
import LocRatingMap from '@components/map/loc-rating-map';
import { MapMarkerInfoBox } from '@components/map/map-marker';

const Cards = () => {
  
    const data = useStaticQuery(graphql`
    query {
      allStrapiOffices {
        edges {
          node {
            Office_Image {
              childImageSharp {
                fluid(quality: 90,toFormat: JPG, maxWidth: 700, maxHeight: 250, cropFocus: CENTER, fit: INSIDE, background: "#fff") {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            Latitude
            Longitude
            Address
            Email
            Office_Name
            Phone
            URL
          }
        }
      }
    }`);

    // Get Map Details
    let mapItems = [];
    {data.allStrapiOffices.edges.map(({node}, key) => {
        let mapItem = {};
        mapItem['lat']  = node.Latitude;        
        mapItem['lng']  = node.Longitude;
        const mapMarkerImageShowFlag = 0;
        let mapItemHtml = MapMarkerInfoBox(node.Office_Name + node.Address, mapMarkerImageShowFlag);
        console.log( mapItemHtml );
        mapItem['html'] = mapItemHtml;
        mapItems.push( mapItem );
    })};

    return (
    <>
    <Container>
    <div 
    className="cards office-cards"
    data-sal="fade"
    data-sal-delay="300"
    data-sal-easing="ease" >
        <Row>

      {data.allStrapiOffices.edges.map(({node}, key) => {
        return <>
            <Col lg="6">
                <Card className="office-card">
                    <Img variant="top" fluid={node.Office_Image.childImageSharp.fluid} className="office-tile-image"/>
                    <Card.Body>
                        <Card.Title>
                            <Link to={`${node.URL}`}>{node.Office_Name}</Link>
                        </Card.Title>
                        <p>{node.Address}</p>
                        <p><a href={`tel:+${node.Phone}`}>{node.Phone}</a> / <Link to="/contact">Email</Link> / <Link to={`${node.URL}`}>Office Details</Link></p>
                    </Card.Body>
                </Card>
            </Col>
        </>
      })}
        </Row>
    </div>
    </Container>

    {/* <Map /> */}
    <LocRatingMap data={mapItems} />

    </>
    );
}

export default Cards;
