import React, { useState } from 'react';
import { Container, Row, Col, Button, Accordion, Tabs, Tab, Form, Card } from 'react-bootstrap';
import classNames from 'classnames';

/**
 * Asset
 */
import './assets/styles/_index.scss';

import MortgageCalc from '@components/calculators/mortgagecalc/';
import StampdutyCalc from '@components/calculators/stampdutycalc/';

const Calculators = ({className}) => {
  const [active, setActive] = useState('+ Read More');
  const [form, setForm] = useState({
    value: '',
    deposit: '',
    rate: '',
    duration: ''
  })

  const handleChange = (event) => {
    const value =  event.target.value;
    const name = event.target.getAttribute('name');
    setForm({
      ...form,
      [name]: value
    })
  }
  return (
    <div className={classNames('property-info calculators', className)} id="calculators">
      <Container id="details" className="details">
        <Row>
          <Col lg={7}>
            <Tabs>
              <Tab eventKey="calculator" title="Mortgage Calculator">
                <p>Use our calculator to work out potential mortgage repayments. Please note, this calculator is for general interest only and must not be relied on.</p>

                <MortgageCalc propertyPrice="4500000" />

              </Tab>
              <Tab eventKey="stamp" title="Stamp Duty">
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus, quos in itaque obcaecati a dicta quo dolorem ratione?</p>

                <StampdutyCalc propertyValue="10000000" propertyType="residential" buyerType="first" />

              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Calculators;
