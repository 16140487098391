import React from "react";

/**
 * Assets
 */
import StarRating from '@static/svg/star_rating.svg';

/**
 * Components
 */

const ReviewItem = ({author, children}) => {
    return (
        <div className="review-item">
            <div className="review-item__author">{author}</div>
            <div className="review-item__icon"><StarRating /></div>
            <div className="review-item__body">
                {children}
            </div>
        </div>
    );
}

export default ReviewItem;