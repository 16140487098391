import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import { isMobile } from "react-device-detect";

/**
 * Assets
 */
import './assets/styles/_index.scss';

/**
 * Components
 */
import ReviewItem from './review-item';

const ReviewsGrid = () => {
    const data = useStaticQuery(graphql`
    query {
        google_review: file(relativePath: { eq: "google-reviews.png" }) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }`);
    return (
        <div className="reviews-grid">
            <Container>
                <Row className="align-items-center rated-row">
                    <Col xs="auto">
                        <img src={data.google_review.childImageSharp.fluid.src} alt="Googel Reviews"/>
                    </Col>
                    <Col>
                        {isMobile ? <p>Rated 4.5+/5 by over <a href="/">320 customers</a>.</p> : <p>Rated 4.5+/5 by over 320 customers. <a href="/">Why not experience the same?</a></p> }
                    </Col>
                </Row>
                <div className="reviews-grid__row">
                    <div className="col">
                        <ReviewItem 
                            author="James Smith" 
                        >
                            <p>Recently sold through Holroyds and Ben and his team were fantastic. Previously my house was with a different agent for months with no joy, Holroyds sold within a week and kept me updated throughout the conveyancing process. Would highly recommend.</p>
                        </ReviewItem>
                        <ReviewItem 
                            author="Space Otter" 
                        >
                            <p>The move did not go 100% correct due to a problem outside of our control but they helped up through it and fought our corner well.</p>
                        </ReviewItem>
                    </div>
                    <div className="col">
                        <ReviewItem 
                            author="Rebecca Brandon" 
                        >
                            <p>Ben, Rebecca and the team successfully let out our property very quickly and professionally, haven’t had any issues. Very pro active estate agent and work hard to make things happen. Highly recommend.</p>
                        </ReviewItem>
                        <ReviewItem 
                            author="Micharl Ethrington" 
                        >
                            <p>I've had multiple transactions with Holroyds and found them to be absolutely fantastic. Really helpful and efficient, highly recommend them if you're buying or selling your home. Good Luck</p>
                        </ReviewItem>
                    </div>
                    <div className="col">
                        <ReviewItem 
                            author="Lajos Tabori" 
                        >
                            <p>Friendly and helpful service. Impressed by their straightforward approach</p>
                        </ReviewItem>
                        <ReviewItem 
                            author="Ryan Overton" 
                        >
                            <p>Attentive staff who made the process of buying a house stress free and well organised.</p>
                            <p>Tony Kay was extremely helpful throughout the mortgage application and provided a great continued service to ensure I had the appropriate insurance cover. </p>
                        </ReviewItem>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default ReviewsGrid;