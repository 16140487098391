import React, { useState } from 'react';
import { Container, Row, Col, Button, Accordion, Tabs, Tab, Form, Card } from 'react-bootstrap';
import classNames from 'classnames';
import ReactMarkdown from "react-markdown"

/**
 * Asset
 */
import './assets/styles/_index.scss';


const OfficeInfoSidebar = (props, {className}) => {

  return (
    <div className="office-details-info">
        <div className="details">
            <Card>
              <Row className="align-items-center">
                <Col md={6} lg={12}>
                  <Card.Img variant="top" src={props.Image} alt="store" />
                </Col>
                <Col md={6} lg={12}>
                  <Card.Body>
                    <h5>{props.Name}</h5>
                    <h6>Contact</h6>
                    <p className="address"><ReactMarkdown source={props.Address} /></p>
                    <p><a href={`tel:+${props.Phone}`}>{props.Phone}</a> / <a href="/contact">Email Office</a></p>
                    <div className="hours">
                      <h6>Opening Hours</h6>
                      <p><ReactMarkdown source={props.Opening_Hours} /></p>
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
        </div>
    </div>
  )
}

export default OfficeInfoSidebar;
