import React from "react";
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import ReactMarkdown from "react-markdown"


/**
 * Components
 */
import Video from '@components/video/video';
import NoVideo from '@components/video/without-video';
import ModalVideo from '@components/video/video-modal';
import Relative from '@components/relative/relative';
import CalendlyCTAOne from '@components/calendly/global-cta-one';

import Holder from '@components/holder/holder';

const SeamlessExperienceWithLinks = (props,{className}) => {
  
  var offset = ""

  if(props.Image_Alignment == "Right")
  {
    var offset = 0
  }
  else if(props.Image_Alignment == "Left")
  {
    var offset = 1  
  }

  if(props.CTA_URL != null) {
    if ((props.CTA_URL.match('http')) || (props.CTA_URL.match('https'))) {
      var Link_Type = "external"
    }
    else {
      var Link_Type = "internal"
    }
  }

  return (
<div className="offices">
  <Relative>
    <Container>
        <Row>
        {props.Image_Alignment == "Left" &&
            <Col 
                lg={6} 
                className="is-static is-video"
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
            >
            {props.Upload_Video != null && props.Upload_Video != "#" &&
              <Video 
                width="48.9%" 
                height="600px" 
                align="left" 
                image={props.Image} 
                video={props.Upload_Video.publicURL} 
              />
              }
              {props.Embed_Video_URL != null && props.Embed_Video_URL != "#" &&
                <ModalVideo 
                  width="48.9%" 
                  height="600px" 
                  align="left" 
                  image={props.Image} 
                  video={props.Embed_Video_URL}
                  name={props.Title}
                />
              }
              {props.Upload_Video == null && props.Embed_Video_URL == null &&
                <NoVideo 
                  width="48.9%" 
                  height="600px" 
                  align="left" 
                  image={props.Image} 
                />
              }
            </Col>
            }
            <Col 
                lg={{span: 5, offset: offset}} 
                className="align-self-center text-box text pl-md-5"
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
            >
                <h4>{props.Title}</h4>
                  <p>
                    <ReactMarkdown source={props.Content} />
                  </p>
                <ul className="arrow-list">
                  {props.ServiceLinks.map((ServiceLinks, key) => {
                    if(ServiceLinks.URL != null) {
                      if ((ServiceLinks.URL.match('http')) || (ServiceLinks.URL.match('https'))) {
                        var Service_Link_Type = "external"
                      }
                      else {
                        var Service_Link_Type = "internal"
                      }
                    }
                    return <>
                      <li>
                      {Service_Link_Type == "external" &&
                        <a href={ServiceLinks.URL} target="_blank" rel="nofollow">{ServiceLinks.Label}</a>
                      }
                      {Service_Link_Type == "internal" &&
                        <Link to={`/${ServiceLinks.URL}`}>{ServiceLinks.Label}</Link>
                      }
                     </li>
                    </>
                  })}
                </ul>
                {props.CTA_URL !== null && props.CTA_URL !== "#calendly" &&
                <Holder maxWidth="271px" mobile={false}>
                        
                {Link_Type == "external" &&
                  <a href={props.CTA_URL} target="_blank" rel="nofollow" className="btn btn-primary">{props.CTA_Label}</a>
                }
                {Link_Type == "internal" &&
                  <Link to={`/${props.CTA_URL}`} className="btn btn-primary">{props.CTA_Label}</Link>
                }
                </Holder>
                }


                {props.CTA_URL !== null && props.CTA_URL === "#calendly" &&
                <Holder maxWidth="271px" mobile={false}>
                  <CalendlyCTAOne url={props.Calendly}/>
                </Holder>
                }
            </Col>


        {props.Image_Alignment == "Right" &&
            <Col 
                lg={6} 
                className="is-static is-video"
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
            >
            {props.Upload_Video != null && props.Upload_Video != "#" &&
            <Video 
              width="48.9%" 
              height="600px" 
              align="right" 
              image={props.Image} 
              video={props.Upload_Video.publicURL} 
            />
              }
              {props.Embed_Video_URL != null && props.Embed_Video_URL != "#" &&
            <Video 
              width="48.9%" 
              height="600px" 
              align="right" 
              image={props.Image} 
              video={props.Embed_Video_URL} 
            />
              }
              {props.Upload_Video == null && props.Embed_Video_URL == null &&
                <NoVideo 
                  width="48.9%" 
                  height="600px" 
                  align="right" 
                  image={props.Image} 
                />
              }
            </Col>
            }
        </Row>
    </Container>
  </Relative>
</div>
  )
}

export default SeamlessExperienceWithLinks;
